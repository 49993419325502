/*eslint-disable */
<template>
  <b-modal v-model="showSCModal" centered scrollable :title="propSubsPlanDetails.subs_plan_name" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" >
      <b-row>
        <b-col md="12">
          <b-card no-body>
            <b-card-text class="p-0">
              <!-- <div>
                <h5>
                  Modules: <span class="textSpan">{{propSubsPlanDetails.subs_plan_modules}}</span>
                </h5>
              </div> -->

              <div class="mt-3" v-if="propSubsPlanDetails.subs_plan_desc && propSubsPlanDetails.subs_plan_desc.length">
                <label>Details</label>
                <!-- <p v-html="propSubsPlanDetails.subs_plan_desc">
                </p> -->
                <li v-for="(subsModule, indexM) in utility.splitTextBy(propSubsPlanDetails.subs_plan_desc, '.')" :key="indexM+'B'">
                  {{ subsModule }}
                </li>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closePDModal()">
        Close
      </b-button>
    </template>
  </b-modal>
</template>
<style>
.active_interest {
  background-color: #51B5FD!important;
  border-color: #51B5FD!important;
}
.active_interest span {
  color: #FFFFFF!important;
}.textSpan {
  color: var(--iq-body-text);
  text-transform: capitalize;
  font-size: 15px;
  margin-left: 13px;
}
</style>
<script>
import Utility from "../Utils/utility"
export default {
  name: "PlanDetail",
  props: {
    "propShowPDModal": {
      default: false
    },
    "propSubsPlanDetails": {
      default: {}
    }
  },
  components: {
  },
  data () {
    return {
      cvCardTitle: "Plan Details",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "PlanDetail Response",
      submitting: false,
      showSCModal: false,
      utility: Utility
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  methods: {
    /**
     * getSubsModules
     */
    getSubsModules (subsPlan) {
      let subsModules = []
      try {
        subsModules = subsPlan.subs_plan_modules.split(",")
      }
      catch (err) {
        console.log("Exception occurred at getSubsModules() and Exception:", err.message)
      }
      return subsModules
    },
    /**
     * closePDModal
     */
    closePDModal () {
      this.showSCModal = false
      this.$emit("emitClosePDModal", true)
    }
  },
  watch: {
    async propShowPDModal () {
      if (this.propShowPDModal) {
        this.submitting = false
        this.showSCModal = true
      }
    }
  }
}
</script>
