/*eslint-disable */
<template>
  <b-modal v-model="showSCModal" centered scrollable :title="cvCardTitle" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" style="height: 500px;">
      <b-row>
        <b-col md="12">
          <b-card no-body>
            <b-card-text class="p-0">
              <div v-if="cvBuySubForOptions.length > 1">
                <label>{{cvSubsForLabel}} <strong style="color:red;">*</strong></label>
                <multiselect
                  v-model="vmModuleObjId"
                  :options="cvBuySubForOptions"
                  track-by="id"
                  label="name"
                  :placeholder="cvSelectLabel + cvSubsForLabel"
                  :tag-placeholder="cvSelectLabel + cvSubsForLabel">
                </multiselect>
              </div>

              <div class="mt-3">
                <label>Terms and Conditions <strong style="color:red;">*</strong></label>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Feugiat nisl pretium fusce id. Consequat id porta nibh venenatis. Non blandit massa enim nec dui nunc mattis enim ut. Diam vulputate ut pharetra sit amet. Ornare arcu odio ut sem nulla pharetra diam sit amet. Sed velit dignissim sodales ut eu. Nibh nisl condimentum id venenatis a. Libero enim sed faucibus turpis. Pellentesque habitant morbi tristique senectus. Sit amet consectetur adipiscing elit ut aliquam. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Sed sed risus pretium quam vulputate dignissim suspendisse. Tristique magna sit amet purus gravida quis. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Amet massa vitae tortor condimentum lacinia quis. Nisi scelerisque eu ultrices vitae. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus a.

                  Elementum facilisis leo vel fringilla est ullamcorper eget nulla facilisi. Vel quam elementum pulvinar etiam. Sit amet est placerat in. Iaculis at erat pellentesque adipiscing. Enim tortor at auctor urna nunc id cursus metus. Nulla facilisi morbi tempus iaculis. Lobortis mattis aliquam faucibus purus in massa tempor. Commodo elit at imperdiet dui accumsan. Tempor nec feugiat nisl pretium fusce id. Gravida cum sociis natoque penatibus. Aliquam ultrices sagittis orci a. Risus feugiat in ante metus dictum at. Massa tempor nec feugiat nisl pretium fusce. Consectetur adipiscing elit duis tristique. Eget gravida cum sociis natoque penatibus et magnis. Ac feugiat sed lectus vestibulum mattis ullamcorper velit sed. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi.

                  Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique. Dictum at tempor commodo ullamcorper a lacus. Nulla pharetra diam sit amet nisl. Pellentesque habitant morbi tristique senectus et netus et. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac. Massa tempor nec feugiat nisl pretium fusce. Odio facilisis mauris sit amet massa vitae. Mollis aliquam ut porttitor leo. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Bibendum neque egestas congue quisque. Varius quam quisque id diam vel quam elementum pulvinar etiam. Nec feugiat in fermentum posuere urna nec. Massa eget egestas purus viverra accumsan in nisl nisi. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Ultrices neque ornare aenean euismod. Vitae congue eu consequat ac felis donec et. Tortor condimentum lacinia quis vel eros donec ac odio. Massa massa ultricies mi quis hendrerit dolor magna eget est. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida hendrerit.

                  Vulputate eu scelerisque felis imperdiet proin fermentum. Purus sit amet luctus venenatis lectus magna fringilla urna. Urna nunc id cursus metus aliquam. Volutpat sed cras ornare arcu dui vivamus arcu. Felis imperdiet proin fermentum leo vel. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla. Convallis posuere morbi leo urna molestie at elementum eu. Semper viverra nam libero justo laoreet sit amet. Consequat mauris nunc congue nisi. Pellentesque elit eget gravida cum. Sodales ut eu sem integer. Eget nullam non nisi est sit. Duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Aliquam purus sit amet luctus venenatis lectus. Adipiscing diam donec adipiscing tristique. Elementum facilisis leo vel fringilla est. Aliquet enim tortor at auctor. Amet luctus venenatis lectus magna fringilla. Donec massa sapien faucibus et molestie ac. Viverra accumsan in nisl nisi scelerisque.

                  Purus viverra accumsan in nisl nisi. Malesuada fames ac turpis egestas sed. Pharetra pharetra massa massa ultricies mi quis hendrerit. Suspendisse ultrices gravida dictum fusce ut placerat orci nulla. Ornare lectus sit amet est placerat in egestas erat imperdiet. Amet consectetur adipiscing elit pellentesque habitant morbi. Mattis pellentesque id nibh tortor id. Semper feugiat nibh sed pulvinar proin gravida hendrerit. Fermentum leo vel orci porta non pulvinar neque laoreet. At quis risus sed vulputate odio. In mollis nunc sed id semper risus in. Sed vulputate mi sit amet mauris commodo. Vitae ultricies leo integer malesuada. Morbi quis commodo odio aenean sed adipiscing diam donec. Turpis nunc eget lorem dolor sed.
                </p>

                <div class="custom-control custom-checkbox d-inline-block mb-2">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="tnc">
                  <label class="custom-control-label" for="customCheck1">I accept <a href="/content/Tnc" target="_blank">Terms and Conditions</a></label>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeSCModal()">
        Cancel
      </b-button>
      <b-button size="sm" variant="primary" @click="confirmSubscription()" :disabled="submitting">
        <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
        </b-spinner>
        <span v-else>Confirm</span>
      </b-button>
    </template>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-modal>
</template>
<style>
.active_interest {
  background-color: #51B5FD!important;
  border-color: #51B5FD!important;
}
.active_interest span {
  color: #FFFFFF!important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"

export default {
  name: "SubscriptionConfirmation",
  props: {
    "propShowSCModal": {
      default: false
    },
    "propSubsPlanDetails": {
      default: {}
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      cvCardTitle: "Plan Details",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Subscription Confirmation Response",
      totalRows: 0,
      page: 1,
      submitting: false,
      showSCModal: false,
      cvBuySubForOptions: [],
      cvSubsForLabel: "Buy Subscription for",
      vmModuleObjId: null,
      cvSelectLabel: "Select ",
      tnc: false,
      showTncErrBox: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  methods: {
    /**
     * closeSCModal
     */
    closeSCModal () {
      this.showSCModal = false
      this.$emit("emitCloseSCModal", true)
    },
    /**
     * confirmSubscription
     */
    async confirmSubscription () {
      if (this.cvBuySubForOptions.length === 1) {
        this.vmModuleObjId = this.cvBuySubForOptions[0]
      }
      else if (this.cvBuySubForOptions.length > 1) {
        if (!this.vmModuleObjId) {
          this.toastVariant = "danger"
          this.toastMsg = "Please select subscription for."
          this.showToast = true
          return false
        }
      }
      if (!this.tnc) {
        this.toastVariant = "danger"
        this.toastMsg = "Please accept the terms and conditions."
        this.showToast = true
        return false
      }

      let data = {
        "subs_plan_id": this.propSubsPlanDetails.subs_plan_id,
        "module_obj_id": this.vmModuleObjId["id"],
        "module_obj_type": this.vmModuleObjId["type"],
        ...this.propSubsPlanDetails
      }
      await this.$store.commit("Paygw/addSelectedPlanCommit", data)
      this.$router.push("/pay_gw")
    },
    /**
     * buySubFor
     */
    buySubFor () {
      this.cvBuySubForOptions = []
      if (this.userData.user_role === "USERROLE11115" || this.userData.user_role === "USERROLE11116") {
        if (this.userData.modules.organisations) {
          let orgs = this.userData.modules.organisations
          for (let index in orgs) {
            let org = orgs[index]
            let allowedToPost = this.getUserAllowedToPostOrNot(org.org_id)
            if (allowedToPost) {
              this.cvBuySubForOptions.push(
                { name: `${org.org_name}`, id: org.org_id, "type": "UNIV" }
              )
            }
          }
        }
      }
      else if (this.userData.user_role === "USERROLE11113" || this.userData.user_role === "USERROLE11114") {
        this.cvBuySubForOptions.push(
          { name: `${this.userData.user_name}`, id: this.userData.user_id, "type": "STUDENT" }
        )
      }
      else if (this.userData.user_role === "USERROLE11112") {
        this.cvBuySubForOptions.push(
          { name: `${this.userData.user_name}`, id: this.userData.user_id, "type": "EDUCATOR" }
        )
      }
    },
    /**
     * getUserAllowedToPostOrNot
     * @param moduleObjId
     * @returns {boolean}
     */
    getUserAllowedToPostOrNot (moduleObjId) {
      if (this.userData.roles && this.userData.roles[moduleObjId]) {
        if (this.userData.roles[moduleObjId]["udr_name"].toLowerCase() === "admin") {
          return true
        }
      }
      else {
        return false
      }
    }
  },
  watch: {
    async propShowSCModal () {
      if (this.propShowSCModal) {
        this.submitting = false
        this.showSCModal = true
        this.buySubFor()
      }
    }
  }
}
</script>
